import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../components/footer'

import heroStyles from '../components/hero.module.css'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

class InfoPageTemplate extends React.Component {
  render() {
    const infoPage = get(this.props, 'data.contentfulInfoPage')
    const infoPageList = get(this.props, 'data.allContentfulInfoPage.edges')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <Helmet title={`${infoPage.title} | ${siteTitle}`} />        
         <div class="sub_header_in sticky_header">
            <div class="container">
              <h1>{infoPage.title}</h1>
            </div>
          </div>  
         <main>
          <div class="container margin_60_35">
            <div class="row">
              <aside class="col-lg-3" >
                <div class="box_style_cat" id="faq_box">
                  <ul id="cat_nav">
                    {infoPageList && infoPageList.map(pageNode => 
                       <li>
                         <Link to={ '/info/' + pageNode.node.slug + '/' }>
                          <i class="icon_document_alt"></i>{pageNode.node.title}
                         </Link>    
                        </li>
                      )}                    
                  </ul>
                </div>
              </aside>
              <div class="col-lg-9" >
                  <div class="more_padding_left">
                  {documentToReactComponents(infoPage.body.json)}
                  </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default InfoPageTemplate

export const pageQuery = graphql`
  query InfoPageBySlug($slug: String!) {
    contentfulInfoPage(slug: { eq: $slug }) {
      title
      slug      
      body {
        json        
      }
    }
    allContentfulInfoPage {
      edges {
        node {
          title
          slug                
        }
      }
    }
  }
`
